/**
 * @generated SignedSource<<3f2543a4ad4a8c11a3bd22a267ef8163>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type displayStoryCardCarouselWidgetQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersStyleArticleListQuery">;
  readonly " $fragmentType": "displayStoryCardCarouselWidgetQuery";
};
export type displayStoryCardCarouselWidgetQuery$key = {
  readonly " $data"?: displayStoryCardCarouselWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"displayStoryCardCarouselWidgetQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "displayStoryCardCarouselWidgetQuery"
};

(node as any).hash = "e83781bdd44837d12f25bbf33403bbec";

export default node;
