/**
 * @generated SignedSource<<b6dfdc44fab8ea17df52a7cc4020435d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type atomsCurrentStyleCardCarouselWidgetSectionsAtomBase$data = {
  readonly __typename: "Article";
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
      readonly name: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "atomsCurrentStyleCardCarouselWidgetSectionsAtomBase";
} | {
  readonly __typename: "Section";
  readonly fullSectionPath: ReadonlyArray<{
    readonly entityId: string;
    readonly name: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "atomsCurrentStyleCardCarouselWidgetSectionsAtomBase";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "atomsCurrentStyleCardCarouselWidgetSectionsAtomBase";
};
export type atomsCurrentStyleCardCarouselWidgetSectionsAtomBase$key = {
  readonly " $data"?: atomsCurrentStyleCardCarouselWidgetSectionsAtomBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"atomsCurrentStyleCardCarouselWidgetSectionsAtomBase">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "atomsCurrentStyleCardCarouselWidgetSectionsAtomBase"
};

(node as any).hash = "de65d52e4b820f3cf4c594cc6115c4ed";

export default node;
