import styled from "@emotion/styled";

import { ZIndexLayer } from "shared/lib/styles";

import StyleCardCarouselTagIcon from "./style-card-carousel-tag-icon.svg";

export const Container = styled.div`
  position: sticky;
  inset-block-end: 0;
  inset-inline-start: 0;
  z-index: ${ZIndexLayer.StoryCardCarouselWidget};

  display: flex;
  align-items: flex-start;

  inline-size: 100%;

  background-color: #f6f6f6;

  overflow: hidden;
`;

export const Wrapper = styled.div`
  display: flex;

  inline-size: 100%;
`;

export const StyledLabelIcon = styled(StyleCardCarouselTagIcon)`
  inline-size: 13px;
  block-size: 92px;
  margin-block-start: 10px;
  margin-inline: 8px 0;
`;

export const CarouselContainer = styled.div`
  display: flex;

  inline-size: 100%;
  margin-block-start: 6px;
  padding-block: 0;
  padding-block-end: 10px;
  padding-inline-start: 8px;

  overflow-x: auto;
`;

export const ItemContainer = styled.div`
  inline-size: 72px;
  margin-inline-end: 8px;
`;
