import { useAsync } from "@react-hookz/web";
import camelcaseKeys from "camelcase-keys";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";

import { config } from "shared/data";

import { appAtom } from "scmp-app/lib/app/atoms";
import type { acquisitionConfigAppConfigQuery } from "scmp-app/queries/__generated__/acquisitionConfigAppConfigQuery.graphql";

export const useAcquisitionConfig = () => {
  const appAtomValue = useAtomValue(appAtom);
  const environment = useRelayEnvironment();
  const [state, { execute }] = useAsync(async () => {
    const data = await fetchQuery<acquisitionConfigAppConfigQuery>(
      environment,
      graphql`
        query acquisitionConfigAppConfigQuery {
          appConfig(filter: { entityId: "acquisition" }) {
            json
          }
        }
      `,
      {},
      {
        fetchPolicy: "store-or-network",
      },
    ).toPromise();

    if (!data?.appConfig?.json) return;
    const raw = camelcaseKeys(data.appConfig.json as Record<string, unknown>, {
      deep: true,
    }) as AcquisitionConfigs;
    return config.general.env === "production" ? raw.production : raw.dev;
  });

  useEffect(() => {
    if (
      state.status !== "not-executed" ||
      !appAtomValue.advertisementCampaignConfigLoaded ||
      !appAtomValue.advertisementConfigLoaded
    )
      return;
    void execute();
  }, [
    appAtomValue.advertisementCampaignConfigLoaded,
    appAtomValue.advertisementConfigLoaded,
    execute,
    state.status,
  ]);

  return { acquisitionConfigState: state };
};

type AcquisitionConfig = Record<
  string,
  {
    list: { id: string; trackingEventLabel: string }[];
    name: string;
  }
> & {
  styleStoryCarousel: {
    display: boolean;
  };
  tooltip: {
    message: string;
    version: string;
  };
};
type AcquisitionConfigs = {
  dev: AcquisitionConfig;
  production: AcquisitionConfig;
};
