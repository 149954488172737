/**
 * @generated SignedSource<<7f698cea9ae841d8d43c03683d784376>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersIsPublishedWithin24HoursQueueItemConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly publishedDate?: number | null | undefined;
    };
  }>;
  readonly " $fragmentType": "helpersIsPublishedWithin24HoursQueueItemConnection";
};
export type helpersIsPublishedWithin24HoursQueueItemConnection$key = {
  readonly " $data"?: helpersIsPublishedWithin24HoursQueueItemConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersIsPublishedWithin24HoursQueueItemConnection">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersIsPublishedWithin24HoursQueueItemConnection"
};

(node as any).hash = "ddb8454f2545902de3fae50ac0d79388";

export default node;
